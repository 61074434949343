import { makeAutoObservable, toJS } from "mobx";
import map from "lodash/map";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import compact from "lodash/compact";

import agent from "@utils/agent";

class SellCreateStore {
  values = { phone_country_code: "61" };
  isCreatingSales = false;
  createSalesError = "";
  appraisals = [];

  constructor() {
    makeAutoObservable(this);
  }

  createSales(cb) {
    this.isCreatingSales = true;
    return agent.Sales.create(this.values)
      .then((res) => typeof cb === "function" && cb(res))
      .catch((err) => {
        console.log("err: ", err);
        // this.createSalesError =
        //   err.response && err.response.body && err.response.body.errors;
        // throw err;
      })
      .finally(() => {
        this.isCreatingSales = false;
      });
  }

  update(value = {}) {
    this.values = { ...this.values, ...value };
  }

  getAppraisals(markets) {
    const items = get(this.values, "bundle_attributes.items_attributes", []);
    this.appraisals = map(items, (o) => {
      const index = findIndex(markets, (m) => m.id == o.quota_market_id);
      const market = markets[index];
      const name = compact([
        get(market, "quota.state.name", ""),
        get(market, "quota.zone.name", ""),
        get(market, "quota.species.name", ""),
      ]).join(" ");
      const prices = get(market, "instant_price.price_with_symbol", "");
      // 10 x TAS Abalone Quota Units = $40-45k each
      return `${o.quantity} x ${name} = ${prices} each`;
    });
  }
}

export default new SellCreateStore();
