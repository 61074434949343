import React from "react";
import { observer } from "mobx-react-lite";

import Validation from "@components/forms/validation";

const Step1 = ({ index, dispatch, setOpenTab, isLast, store }) => {
  const onSubmit = (data) => {
    dispatch({ type: "validate", index: index });
    dispatch({ type: "enable", index: index + 1 });
    store.update(data);
    setOpenTab(index + 1);
  };
  let items = [
    {
      label: "Email",
      error: { required: "Please enter a valid email" },
      name: "email",
      type: "email",
      placeholder: "Enter you email",
    },
    {
      label: "Mobile",
      error: { required: "Please enter your mobile" },
      name: "phone_number",
      type: "number",
      placeholder: "Enter you mobile",
    },
    {
      label: "First name",
      error: { required: "Please enter your first name" },
      name: "first_name",
      type: "text",
      placeholder: "Enter you first name",
    },
    {
      label: "Last name",
      error: { required: "Please enter your last name" },
      name: "last_name",
      type: "text",
      placeholder: "Enter you last name",
    },
  ];
  return (
    <div className="flex flex-col">
      <Validation items={items} onSubmit={onSubmit} submitBtn="Next"/>
    </div>
  );
};

export default observer(Step1);
