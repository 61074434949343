import React, {useEffect, useCallback} from "react";
import { observer, useLocalObservable } from "mobx-react-lite";

import { FisheriesStore } from "@stores";
import Validation from "@components/forms/validation";

const Step2 = ({ index, dispatch, setOpenTab, isLast, store }) => {
  const fisheriesStore = useLocalObservable(() => {
    return FisheriesStore;
  });

  useEffect(() => {
    fisheriesStore.loadFisheries();
  }, []);

  const onSubmit = useCallback((data) => {
    dispatch({ type: "validate", index: index });
    dispatch({ type: "enable", index: index + 1 });
    store.update(data);
    setOpenTab(index + 1);
  }, []);
  let items = [
    {
      label: "Fishery",
      error: { required: "Please enter a fishery" },
      name: "fishery_id",
      type: "select",
      options: fisheriesStore.getFisheryOptions()
    }
  ];
  return (
    <div className="flex flex-col">
      <Validation items={items} onSubmit={onSubmit} submitBtn="Next"/>
    </div>
  );
};

export default observer(Step2);
