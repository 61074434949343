import React, { useEffect, useCallback } from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import get from "lodash/get";

import { QuotaMarketsStore } from "@stores";
import MarketFields from "./marketFields";

const defaultValues = {
  bundle_attributes: {
    items_attributes: [{ quota_market_id: "", quantity: "" }],
  },
};

const Step3 = ({ index, dispatch, setOpenTab, isLast, store }) => {
  const fisheryId = get(store.values, "fishery_id", "");

  const quotaMarketsStore = useLocalObservable(() => {
    return QuotaMarketsStore;
  });

  useEffect(() => {
    fisheryId && quotaMarketsStore.getQuotaMarkets(fisheryId);
  }, [fisheryId]);

  const onSubmit = useCallback(
    (data) => {
      dispatch({ type: "validate", index: index });
      dispatch({ type: "enable", index: index + 1 });
      store.update(data);
      store.getAppraisals(quotaMarketsStore.quotaMarkets);
      setOpenTab(index + 1);
    },
    [quotaMarketsStore.quotaMarkets]
  );

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({defaultValues});

  return (
    <div className="flex flex-col">
      <form onSubmit={handleSubmit(onSubmit)}>
        <MarketFields
          {...{ control, register, defaultValues, getValues, setValue, errors }}
          quotaMarketOptions={quotaMarketsStore.getQuotaMarketsOptions()}
        />
        <div className="mt-4">
          <button
            type="submit"
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
          >
            Get my instant offer
          </button>
        </div>
      </form>
    </div>
  );
};

export default observer(Step3);
