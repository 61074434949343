import { makeAutoObservable, toJS } from "mobx";
import get from "lodash/get";
import compact from "lodash/compact";
import agent from "@utils/agent";

class QuotaMarketsStore {
  isLoadingQuotaMarkets = false;
  quotaMarkets = [];

  constructor() {
    makeAutoObservable(this);
  }

  getQuotaMarkets(slug) {
    this.isLoadingQuotaMarkets = true;
    return agent.QuotaMarkets.get(slug)
      .then((data) => {
        this.quotaMarkets = data;
      })
      .catch((err) => {
        console.log(err)
        // throw err;
      })
      .finally(() => {
        this.isLoadingQuotaMarkets = false;
      });
  }

  getQuotaMarketsOptions() {
    return this.quotaMarkets.map((o) => ({
      label: compact([
        get(o, "quota.state.name", ""),
        get(o, "quota.zone.name", ""),
        get(o, "quota.species.name", ""),
      ]).join(" "),
      value: o.id,
    }));
  }
}

export default new QuotaMarketsStore();
