import React, { useEffect, createContext, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Home, SellCreate } from "@pages";

import { UserStore } from "@stores";

import "@assets/stylesheets/application.scss";

const UserStoreContext = createContext(UserStore);

const App = () => {
  const userStore = useContext(UserStoreContext);

  useEffect(() => {
    userStore.getCurrentUser();
  }, []);

  return (
    <Switch>
      <Route path="/" exact component={SellCreate} />
    </Switch>
  );
};

export default App;
