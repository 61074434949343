import { userPool } from "./config";

/**
 * Get current CognitoUser from user pool
 * @return {Request<WorkDocs.GetCurrentUserResponse, AWSError> | CognitoUser}
 */
function currentUser() {
  return userPool.getCurrentUser();
}

/**
 * Get the corresponding user session
 * @param {Object} user - The CognitoUser object
 * @return {Promise} - A promise resolves to the session
 */
function userSession(user) {
  return new Promise((resolve, reject) => {
    user.getSession((err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
}

/**
 * Get current user's session
 * @return {Promise} - A promise resolves to session object if success
 */
function currentUserSession() {
  if (!userPool) {
    return Promise.reject("No userPool");
  }

  let cognitoUser = currentUser();
  if (!cognitoUser) {
    return Promise.reject("No current user");
  }
  return userSession(cognitoUser);
}

/**
 * Get current user's session
 * @return {Promise} A promise resolves to session object if success
 */
function currentSession() {
  return currentUserSession();
}

/**
 * Get current idToken from session
 * @return {Promise} A promise resolves to idToken string if success
 */
function currentIdToken() {
  return currentSession().then((session) => session.getIdToken().getJwtToken());
}

/**
 * Retry 5 times for getting current idToken from session
 * @return {Promise} A promise resolves to idToken string if success
 */

const currentIdTokenRetry = async (n = 5) => {
  try {
    return await currentIdToken();
  } catch (err) {
    if (n === 1) throw err;
    return await currentIdTokenRetry(n - 1);
  }
};

export { currentIdToken, currentIdTokenRetry };
