import React from "react";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react-lite";

const Step3 = ({ index, dispatch, setOpenTab, isLast, store }) => {
  const onSubmit = () => {
    store.createSales(() => {
      dispatch({ type: "validate", index: index });
      dispatch({ type: "enable", index: index + 1 });
      setOpenTab(index + 1);
    });
  };
  return (
    <div className="flex flex-col">
      {!isEmpty(store.appraisals) && (
        <ul className="mb-8">
          {map(store.appraisals, (o) => (
            <div className="text-sm font-bold">
              <span>{o}</span>
            </div>
          ))}
        </ul>
      )}
      <button
        type="submit"
        onClick={onSubmit}
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
      >
        List for sale
      </button>
    </div>
  );
};

export default observer(Step3);
