import React from "react";
import PropTypes from "prop-types";

export const Select = ({
  inline = false,
  label = "Label",
  name = "name",
  items = [
    { value: 0, label: "Option 1" },
    { value: 1, label: "Option 2" },
    { value: 2, label: "Option 3" },
    { value: 3, label: "Option 4" },
  ],
  errors = {},
  errorMsg = "",
  register = () => ({}),
  onChange = () => {},
}) => {
  const { onChange: _onChange, ...rest } = register(name, errorMsg);

  return (
    <div className={`form-element ${inline ? "form-element-inline" : ""}`}>
      <div className="form-label">{label}</div>
      <select
        className={`form-select ${errors[name] ? "border border-red-500" : ""}`}
        name={name}
        onChange={(val) => {
          _onChange(val);
          onChange(val);
        }}
        {...rest}
      >
        {items.map((item, i) => (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {errors[name] && <div className="form-error">{errors[name].message}</div>}
    </div>
  );
};

Select.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  errors: PropTypes.object,
  errorMsg: PropTypes.string,
  register: PropTypes.func,
  onChange: PropTypes.func,
};
