import React from "react";
import { useFieldArray } from "react-hook-form";
import { Select } from "@components/forms/selects";
import { TextInput } from "@components/forms/text-inputs";

const Fields = ({ control, register, errors, quotaMarketOptions }) => {
  console.log("quotaMarketOptions: ", quotaMarketOptions);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bundle_attributes.items_attributes",
  });

  return (
    <>
      <ul>
        {fields.map((item, index) => {
          return (
            <li key={item.id} className="mb-4">
              <Select
                label="Quota Market"
                name={`bundle_attributes.items_attributes.${index}.quota_market_id`}
                items={quotaMarketOptions}
                errors={errors}
                register={register}
              />

              <TextInput
                label="Quantity"
                name={`bundle_attributes.items_attributes.${index}.quantity`}
                type="number"
                placeholder="Enter quantity..."
                errors={errors}
                register={register}
                defaultValue={item.quantity}
              />

              <button
                type="button"
                onClick={() => remove(index)}
                className="btn btn-default bg-red-500 hover:bg-red-600 text-white btn-rounded"
              >
                Delete
              </button>
            </li>
          );
        })}
      </ul>
      <button
        type="button"
        onClick={() => {
          append({ quota_market_id: "", quantity: "" });
        }}
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
      >
        Append
      </button>
    </>
  );
};

export default Fields;
