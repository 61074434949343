import React from "react";
import Layout from "@layouts/centered";
import CenteredForm from "@layouts/centeredForm";
import Steps from "./steps";

const Index = () => {
  return (
    <Layout>
      <CenteredForm>
        <Steps />
      </CenteredForm>
    </Layout>
  );
};

export default Index;
