import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { UserStore } from "@stores";
// import authStore from './stores/authStore';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = "/api/v1";

const handleErrors = (err) => {
  if (err && err.response && err.response.status === 401) {
    // authStore.logout();
    console.log("401=====>");
  }
  return err;
};

const responseBody = (res) => res.body;

const tokenPlugin = (req) => {
  if (UserStore.token) {
    req.set("authorization", `Token ${UserStore.token}`);
  }
};

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
};

const Fisheries = {
  all: () => requests.get(`/fisheries`),
};

const QuotaMarkets = {
  get: (slug) => requests.get(`/fisheries/${slug}/quota_markets`),
};

const Sales = {
  create: (data) => requests.post("/instant_sales", data),
};

export default {
  Fisheries,
  QuotaMarkets,
  Sales,
};
