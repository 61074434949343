import { makeAutoObservable, toJS } from "mobx";
import agent from "@utils/agent";

class FisheriesStore {
  isLoadingFisheries = false;
  fisheries = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadFisheries() {
    this.isLoadingFisheries = true;
    return agent.Fisheries.all()
      .then((data) => {
        this.fisheries = data;
      })
      .catch((err) => {
        console.log('err: ', err);
        // throw err;
      })
      .finally(() => {
        this.isLoadingFisheries = false;
      });
  }

  getFisheryOptions() {
    return this.fisheries.map((f) => ({
      label: `${f.state.name} ${f.category.name}`,
      value: f.id,
    }));
  }
}

export default new FisheriesStore();
