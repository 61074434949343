import React from "react";
import Alert from "@components/alerts";
import { FiAlertCircle } from "react-icons/fi";

const Finish = ({ index, dispatch, setOpenTab, isLast }) => {
  return (
    <div className="flex flex-col">
      <Alert
        size="sm"
        color="bg-transparent text-gray-900"
        icon={<FiAlertCircle className="alert-icon" />}
      >
        Thanks You!
      </Alert>
    </div>
  );
};

export default Finish;
