import React from "react";
import PropTypes from "prop-types";

export const TextInput = ({
  inline = false,
  label = "Label",
  name = "name",
  type = "text",
  placeholder = "Enter something...",
  errors = {},
  errorMsg = "",
  register = () => ({}),
  defaultValue,
}) => (
  <div className={`form-element ${inline ? "form-element-inline" : ""}`}>
    {label && <div className="form-label">{label}</div>}
    <input
      name={name}
      type={type}
      className={`form-input ${errors[name] ? "border-red-500" : ""}`}
      placeholder={placeholder}
      defaultValue={defaultValue}
      {...register(name, errorMsg)}
    />
    {errors[name] && <div className="form-error">{errors[name].message}</div>}
  </div>
);
TextInput.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  errorMsg: PropTypes.string,
  register: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
