import { makeAutoObservable } from "mobx";

import { currentIdTokenRetry } from "@utils/cognitoAuth/session";

class UserStore {
  token=""

  constructor() {
    makeAutoObservable(this);
  }

  async getCurrentUser() {
    try {
      currentIdTokenRetry()
        .then((token) => {
          console.log("toke=====>", token);
          this.token = token;
        })
        .catch((err) => {
          // window.Sentry && window.Sentry.captureMessage(err);
        });
    } catch (err) {
      console.log("err: ", err);
      throw err;
    }
  }
}

export default new UserStore();
