import React, { useState, useReducer } from "react";

import { initialState, reducer } from "./reducer";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Finish from "./finish";

import { SellCreateStore } from "@stores";

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openTab, setOpenTab] = useState(0);

  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        <div className="flex flex-wrap flex-col w-full tabs">
          <div className="flex flex-col lg:flex-wrap lg:flex-row w-full mb-8">
            {state.map((tab, key) => (
              <div
                key={key}
                className="flex w-full lg:w-1/5 items-center justify-center"
              >
                <button
                  disabled={tab.disabled}
                  onClick={() => {
                    setOpenTab(tab.index);
                  }}
                  className={`btn btn-default btn-block ${
                    openTab === tab.index
                      ? "bg-blue-500 text-white"
                      : tab.valid
                      ? "bg-green-500 text-white"
                      : ""
                  }`}
                  type="button"
                >
                  {tab.title}
                </button>
              </div>
            ))}
          </div>
          <div className={`tab-content ${openTab === 0 ? "block" : "hidden"}`}>
            <Step1
              index={0}
              isLast={false}
              dispatch={dispatch}
              setOpenTab={setOpenTab}
              store={SellCreateStore}
            />
          </div>
          <div className={`tab-content ${openTab === 1 ? "block" : "hidden"}`}>
            <Step2
              index={1}
              isLast={false}
              dispatch={dispatch}
              setOpenTab={setOpenTab}
              store={SellCreateStore}
            />
          </div>
          <div className={`tab-content ${openTab === 2 ? "block" : "hidden"}`}>
            <Step3
              index={2}
              isLast={false}
              dispatch={dispatch}
              setOpenTab={setOpenTab}
              store={SellCreateStore}
            />
          </div>
          <div className={`tab-content ${openTab === 3 ? "block" : "hidden"}`}>
            <Step4
              index={3}
              isLast={true}
              dispatch={dispatch}
              setOpenTab={setOpenTab}
              store={SellCreateStore}
            />
          </div>
          <div className={`tab-content ${openTab === 4 ? "block" : "hidden"}`}>
            <Finish
              index={4}
              isLast={true}
              dispatch={dispatch}
              setOpenTab={setOpenTab}
              store={SellCreateStore}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
