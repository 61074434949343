import { CognitoUserPool } from "amazon-cognito-identity-js";

/**
 * AWS Cognito and AWS S3 region
 */
const region = "us-east-1";

/**
 * Get AWS Coginto config data
 * dev:
 * UserPoolId: 'us-east-1_7PHs3gU4O',
 * ClientId: '3vjpfgmn0g7j6nqlbrsa4sv1kn',
 * IdentityPoolId: 'us-east-1:648ecbe3-2f65-469f-a070-5991bc16bcc7'
 *
 * staging:
 * UserPoolId: 'us-east-1_xx1oFfZtr',
 * ClientId: '640jgbd2dk8ka3l6ufo9ogrqcl',
 * IdentityPoolId: 'us-east-1:8f5b1a0d-aad2-49bf-9ce1-7a416955481e'
 */
function poolData() {
  const {
    REACT_APP_USER_POOL_ID,
    REACT_APP_CLIENT_ID,
    REACT_APP_IDENTITY_POOL_ID,
    NODE_ENV,
  } = process.env;

  if (NODE_ENV === "production") {
    if (!REACT_APP_USER_POOL_ID) {
      throw new Error("Missing USER_POOL_ID");
    }

    if (!REACT_APP_CLIENT_ID) {
      throw new Error("Missing CLIENT_ID");
    }

    if (!REACT_APP_IDENTITY_POOL_ID) {
      throw new Error("Missing IDENTITY_POOL_ID");
    }
  }

  return {
    UserPoolId: REACT_APP_USER_POOL_ID || "us-east-1_xx1oFfZtr",
    ClientId: REACT_APP_CLIENT_ID || "640jgbd2dk8ka3l6ufo9ogrqcl",
    IdentityPoolId:
      REACT_APP_IDENTITY_POOL_ID ||
      "us-east-1:8f5b1a0d-aad2-49bf-9ce1-7a416955481e",
  };
}

const userPool = new CognitoUserPool(poolData());

export { poolData, userPool, region };
